import React from 'react';
import { Link } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const InvestorReports = () => {
  return (
    <section>

      <BS.Card style={{ marginTop: 0 }}>

        <BS.Card.Header>Investment Rounds</BS.Card.Header>
      </BS.Card>
      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>Pre-Seed Investors</BS.Card.Title>
          <BS.Card.Text>
            Who is the most prolific pre-seed investor in the UK? Deep dive into a growing market.
    </BS.Card.Text>
          <Link to={`/round/pre-seed/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the report</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>

      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>Seed Investors</BS.Card.Title>
          <BS.Card.Text>
            Seed investors have now moved up a level targeting £500k-£1.5M investments.
    </BS.Card.Text>
          <Link to={`/round/seed/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)' , color: 'black'  }}>Get the report</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>

      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>Series A investors</BS.Card.Title>
          <BS.Card.Text>
            VCs and family offices specialised in startup to scale up growth.
    </BS.Card.Text>
          <Link to={`/round/series-a/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the report</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>
      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>Series B investors</BS.Card.Title>
          <BS.Card.Text>
            VCs and family offices that focus on series B and beyond
    </BS.Card.Text>
          <Link to={`/round/series-b/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the report</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>


      <BS.Card style={{ marginTop: 20 }}>

        <BS.Card.Header>Investor Types</BS.Card.Header>
      </BS.Card>
      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>VCs</BS.Card.Title>
          <BS.Card.Text>
            VCs are probably who you think about when you talk startup investment. Discover the most active VCs.
</BS.Card.Text>
          <Link to={`/investor-type/vc/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the VC list</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>

      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>Angel Investors</BS.Card.Title>
          <BS.Card.Text>
            Without Angel investors there would probably be no startups. Get to know the angels in your sector.
</BS.Card.Text>
          <Link to={`/investor-type/angel/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the angel list</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>

      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>Corporate VCs</BS.Card.Title>
          <BS.Card.Text>
            Often forgotten, corporate VCs make massive bets on tech companies and offer a relevant eco-system to startups from day 1.
</BS.Card.Text>
          <Link to={`/investor-type/corporate-vc/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the corporate VC list</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>
      <BS.Card style={{ marginTop: 10 }}>

        <BS.Card.Body>
          <BS.Card.Title>Family Offices</BS.Card.Title>
          <BS.Card.Text>
            Family offices invest on over all rounds from pre-seed to IPOs. Very interesting market.
</BS.Card.Text>
          <Link to={`/investor-type/family-office/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the family office list</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>

      <BS.Card style={{ marginTop: 20 }}>

        <BS.Card.Body>
          <BS.Card.Title>Angel Networks</BS.Card.Title>
          <BS.Card.Text>
            Angel investors can also invest with other angels. It's a great way to see more deals.
</BS.Card.Text>
          <Link to={`/investor-type/angel-network/`}><BS.Button style={{ background: 'rgb(238 243 249)', border: 'rgb(33 98 175)', color: 'black' }}>Get the angel network list</BS.Button></Link>
        </BS.Card.Body>
      </BS.Card>

    </section>
  )
}

export default InvestorReports